import {
  CancelButton,
  SubmitButton,
  TextInputField
} from '@toasttab/buffet-pui-forms'
import cx from 'classnames'
import { Form, Formik } from 'formik'
import React from 'react'
import { object, string } from 'yup'

interface AddModuleFormProps {
  closeForm: () => void
  addOverride: (key: string, value: string) => void
  isOpen: boolean
}

interface ModuleData {
  moduleName: string
  overrideUrl: string
}

const validationSchema = object({
  moduleName: string().required('Module name is required.'),
  overrideUrl: string().url().required('Override url must be a valid url.')
})
export const AddModuleForm = ({
  closeForm,
  isOpen = false,
  addOverride
}: AddModuleFormProps) => {
  const submitForm = ({ moduleName, overrideUrl }: ModuleData) => {
    addOverride(moduleName, overrideUrl)
    closeForm()
  }

  return (
    <Formik<ModuleData>
      onSubmit={async (values, { resetForm }) => {
        submitForm(values)
        resetForm()
      }}
      validationSchema={validationSchema}
      initialValues={{
        moduleName: '',
        overrideUrl: 'https://dev.eng.toastteam.com:9990/bundle.js'
      }}
    >
      <div
        className={cx('ease overflow-hidden transition-all duration-300', {
          'max-h-0': !isOpen,
          'max-h-[1000px]': isOpen
        })}
      >
        <Form className='bg-gray-25 mb-4 flex  flex-col gap-4 rounded px-4 py-4 '>
          <TextInputField
            name='moduleName'
            label='Module Name'
            helperText='Spa name in the import map'
            placeholder='spa-name'
          />
          <TextInputField
            name='overrideUrl'
            label='Override Url'
            helperText='Url to the bundle'
            placeholder='https://dev.eng.toastteam.com:9990/bundle.js'
          />
          <div className='flex justify-end gap-3'>
            <CancelButton
              onClick={() => {
                closeForm()
              }}
            >
              Cancel
            </CancelButton>
            <SubmitButton>Apply Override</SubmitButton>
          </div>
        </Form>
      </div>
    </Formik>
  )
}
