import { resetDuplicateEntriesCache } from '@local/import-map-tool'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import * as React from 'react'
import ReactDOM from 'react-dom'
import { AppSetup } from './components/App'
import './index.css'

// Run on SPA mount to keep the cache active only within the page lifetime
resetDuplicateEntriesCache()

const reactLifecycles = banquetSingleSpaReact({
  React,
  ReactDOM,
  cssScope: 'data-devt',
  singleSpaCssLifecycles,
  rootComponent: AppSetup,
  sentry: {
    publicKey: '1afab818f69e4eb0828fd82ee7cfcf29@o37442',
    projectId: '4505505289273344',
    releaseVersion: process.env.PKG_VERSION
  }
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
