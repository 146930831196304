import { IconButton } from '@toasttab/buffet-pui-buttons'
import { CheckCircleSelectedIcon, CopyIcon } from '@toasttab/buffet-pui-icons'
import { useBanquetProps } from 'banquet-runtime-modules'
import * as React from 'react'
import { useClipboard } from 'use-clipboard-copy'

function useAuthToken() {
  const { auth } = useBanquetProps()
  const [token, setToken] = React.useState('Loading token...')

  React.useEffect(() => {
    auth
      ?.getTokenSilently()
      .then((token) => setToken(token))
      .catch((err) => console.error(err))
  }, [auth])

  return token
}

function ReactiveCopyIcon({ copied }: { copied: boolean }) {
  if (copied) {
    return (
      <CheckCircleSelectedIcon className='text-success' aria-label='Copied' />
    )
  }
  return <CopyIcon aria-label='Copy' />
}

export function Token() {
  const token = useAuthToken()
  const { copy, copied } = useClipboard({ copiedTimeout: 500 })

  return (
    <div className='grid grid-cols-1 gap-2'>
      <h5 className='type-default text-center font-semibold'>Access token</h5>
      <div className='flex flex-row gap-2'>
        <pre className='bg-gray-25 overflow-x-scroll rounded p-2'>
          <code className='type-subhead select-all leading-tight'>{token}</code>
        </pre>
        <IconButton
          onClick={() => copy(token)}
          icon={<ReactiveCopyIcon copied={copied} />}
        />
      </div>
    </div>
  )
}
